import React from 'react'
import styled from '@emotion/styled'
import MuiButton from '@material-ui/core/Button'
import ThemeConsumer from '@/hoc/ThemeConsumer'
import WithStylesProps from '@/hoc/WithStylesProps'
import { darken, lighten } from '@material-ui/core/styles/colorManipulator'
import { fonts } from '@/utils/preset'
import { mq } from '@/utils/helpers'
import { mobile, mobileS } from '@/utils/media'
import { getThemeColor, getThemeContrastColor } from '@/utils/themes'

const ContactLargeButton = props => {
  const { theme, type, scale, color, classes, children, className, ...other } = props
  return (
    <MuiButton
      className={className}
      variant="contained"
      classes={classes}
      {...other}
    >
      { children }
      <Arrow>
        <svg width="6" height="12" viewBox="0 0 6 12" fill="none" xmlns="http://www.w3.org/2000/svg">
          <Arrow_Triangle d="M6 6L0 0V12L6 6Z"/>
        </svg>
      </Arrow>
    </MuiButton>
  )
}

const getStyles = (type, scale, color, theme) => {
  if (type === 'contrast') {
    return {
      backgroundColor: theme.background.contrast,
      color: theme.text.contrast,
      hoverBackgroundColor: darken(theme.background.contrast, 0.1)
    }
  } else if (color) {
    return {
      backgroundColor: getThemeColor(color, theme),
      color: getThemeContrastColor(color, theme),
      hoverBackgroundColor: darken(getThemeColor(color, theme), 0.1)
    }
  } else if (scale) {
    return {
      backgroundColor: theme.scale[scale],
      color: theme.scale.contrast[scale],
      hoverBackgroundColor: theme.scale.hover[scale]
    }
  } else {
    return {
      backgroundColor: theme.background.default,
      color: theme.text.default,
      hoverBackgroundColor: lighten(theme.background.default, 0.1)
    }
  }
}

const styles = props => {
  const styles = getStyles(props.type, props.scale, props.color, props.theme)
  return {
    root: {
      backgroundColor: styles.backgroundColor,
      color: styles.color,
      minHeight: 256,
      padding: '0 32px',
      fontSize: 26,
      fontWeight: 'bold',
      fontFamily: fonts.base,
      boxShadow: 'none',
      borderRadius: 0,
      justifyContent: 'center',
      [`@media ${mq.and(mobile)}`]: {
        padding: '0 16px',
        fontSize: 22,
        minHeight: 196
      },
      [`@media ${mq.and(mobileS)}`]: {
        fontSize: 20,
        padding: '0 16px'
      },
      '&:hover': {
        textDecoration: 'none',
        backgroundColor: styles.hoverBackgroundColor,
        boxShadow: 'none',
        '@media (hover: none)': {
          backgroundColor: styles.backgroundColor
        }
      }
    },
    label: {
      position: 'relative'
    }
  }
}

const Arrow = styled.span`
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  font-size: 0;
`

const Arrow_Triangle = styled.path`
  fill: currentColor;
`

export default ThemeConsumer(WithStylesProps(styles)(ContactLargeButton))
