import React from 'react'
import styled from '@emotion/styled'
import ThemeConsumer from '@/hoc/ThemeConsumer'
import { getThemeColor } from '@/utils/themes'

const ParticleBackground = props => <Root {...props} />

const getColor = props => {
  if (props.type === 'contrast') {
    return props.theme.text.contrast
  } else if (props.scale) {
    return props.theme.scale[props.scale]
  } else if (props.color) {
    return getThemeColor(props.color, props.theme)
  } else {
    return props.theme.text.default
  }
}

const Root = styled.div`
  width: 100%;
  height: 100%;
  background-image: -webkit-repeating-radial-gradient(center center, ${getColor}, ${getColor} 1px, transparent 1px, transparent 100%);
  background-image: -moz-repeating-radial-gradient(center center, ${getColor}, ${getColor} 1px, transparent 1px, transparent 100%);
  background-image: -ms-repeating-radial-gradient(center center, ${getColor}, ${getColor} 1px, transparent 1px, transparent 100%);
  background-image: repeating-radial-gradient(center center, ${getColor}, ${getColor} 1px, transparent 1px, transparent 100%);
  background-size: 4px 4px;
  transform: translate3d(0, 0, 0);
  opacity: 0.6;
`

export default ThemeConsumer(ParticleBackground)
