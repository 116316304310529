import React from 'react'
import MuiButton from '@material-ui/core/Button'
import ThemeConsumer from '@/hoc/ThemeConsumer'
import WithStylesProps from '@/hoc/WithStylesProps'
import { darken, lighten } from '@material-ui/core/styles/colorManipulator'
import { getThemeColor, getThemeContrastColor } from '@/utils/themes'
import { fonts } from '@/utils/preset'
import { mq } from '@/utils/helpers'
import { mobile, mobileS } from '@/utils/media'

const ContactLargeButton = props => {
  const { theme, type, scale, classes, children, className, ...other } = props
  return (
    <MuiButton
      className={className}
      variant="contained"
      classes={classes}
      {...other}
    >
      { children }
    </MuiButton>
  )
}

const getStyles = (type, scale, colorPath, theme) => {
  if (type === 'contrast') {
    return {
      backgroundColor: theme.background.contrast,
      color: theme.text.contrast,
      hoverBackgroundColor: darken(theme.background.contrast, 0.1)
    }
  } else if (scale) {
    return {
      backgroundColor: theme.scale[scale],
      color: theme.scale.contrast[scale],
      hoverBackgroundColor: theme.scale.hover[scale]
    }
  } else if (colorPath) {
    const color = getThemeColor(colorPath, theme)
    const textColor = getThemeContrastColor(colorPath, theme)
    return {
      backgroundColor: color,
      color: textColor,
      hoverBackgroundColor: darken(color, 0.1)
    }
  } else {
    return {
      backgroundColor: theme.background.default,
      color: theme.text.default,
      hoverBackgroundColor: lighten(theme.background.default, 0.1)
    }
  }
}

const styles = props => {
  const styles = getStyles(props.type, props.scale, props.color, props.theme)
  return {
    root: {
      backgroundColor: styles.backgroundColor,
      color: styles.color,
      height: 64,
      padding: '0 24px',
      fontSize: 20,
      fontFamily: fonts.base,
      boxShadow: 'none',
      justifyContent: 'center',
      [`@media ${mq.and(mobile)}`]: {
        fontSize: 18,
        padding: '0 24px'
      },
      [`@media ${mq.and(mobileS)}`]: {
        fontSize: 16
      },
      '&:hover': {
        textDecoration: 'none',
        backgroundColor: styles.hoverBackgroundColor,
        boxShadow: 'none',
        '@media (hover: none)': {
          backgroundColor: styles.backgroundColor
        }
      }
    },
    label: {
      position: 'relative'
    }
  }
}

export default ThemeConsumer(WithStylesProps(styles)(ContactLargeButton))
